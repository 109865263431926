<template>
  <section id="aboutUs" class="about-us">
    <div class="employee">
      <div class="employee__content">
        <h3 class="employee__name">{{ employee_name }}</h3>
        <h3 class="employee__job">{{ employee_job }}</h3>
      </div>
    </div>
    <div class="company">
      <div class="company__text-wrapper">
        <h2 class="company__name">{{ company_name }} BELGEE</h2>
        <p class="company__text">{{ text_1 }}</p>
        <p class="company__text">{{ text_2 }}</p>
        <p class="company__text">{{ text_3 }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import siteData from "@/config.json";

export default {
  name: "AboutUs",
  data() {
    return {
      employee_name: siteData.about_us.employee_name,
      employee_job: siteData.about_us.job,
      company_name: siteData.name,
      text_1: siteData.about_us.about_us_text1,
      text_2: siteData.about_us.about_us_text2,
      text_3: siteData.about_us.about_us_text3,
    };
  },
};
</script>
