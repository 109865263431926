<template>
  <section id="footer" class="footer">
    <div class="footer__info">
      <p class="footer__info-text">
        ООО «Автобан Березовский Плюс» | ОГРН: 1116678000779 ИНН: 6678000591 |
        КПП: 667801001
      </p>
      <a @click="togglePrivacy" class="footer__policy"
        >©{{ getCurrentYear }} | Политика конфиденциальности</a
      >
    </div>
    <a @click="expanded = !expanded" class="footer__disclaimer-btn"
      >Дисклеймер</a
    >
    <transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
    >
      <div id="disclaimer" class="footer__disclaimer" v-show="expanded">
        <h3 class="footer__disclaimer-title">Belgee X50</h3>
        <h4 class="footer__disclaimer-title">
          Максимальная выгода<br />до 320 000 рублей на Belgee X50 (выгода в
          трейд-ин (лояльный/специальный) + выгода в кредит)
        </h4>
        <p class="footer__disclaimer-text">
          Выгода в размере 320 000 рублей указана для автомобилей «Belgee X50»
          (все комплектации) и состоит из выгоды по программе «Трейд-ин» в
          размере 185 000 рублей и выгоды при покупке с использованием кредитных
          средств Банка в размере 135 000.<br /><br />Программа Трейд-ин -
          возможность продажи автомобиля с пробегом, принадлежащего покупателю,
          в зачет стоимости нового автомобиля «Belgee». Единовременная скидка
          185 000 рублей на указанные выше автомобили предоставляется Дилером
          покупателю от розничной цены Автомобиля, приобретаемого по Программе,
          при сдаче в трейд-ин автомобиля марки «Belgee» или другой популярной
          марки (список марок уточняйте в дилерских центрах «Belgee»).<br /><br />При
          приобретении конечным покупателем Автомобиля с использованием
          кредитных средств Банка, по условиям Программы, Дилер снижает
          розничную цену нового Автомобиля для конечного покупателя на сумму не
          менее 135 000 рублей. Выгода по Программе предоставляется при покупке
          нового автомобиля «Belgee» в кредит в любом банке.<br /><br />Реклама.
          ООО «Автобан-Березовский-Плюс». Не оферта. Программа действует с
          01.08.2024 г. по 31.08.2024 г. Количество автомобилей ограничено. ООО
          «Слава Моторс Рус» вправе изменить сроки и условия Программы.
          Подробные условия и наличие автомобилей уточняйте у менеджеров ДЦ
          Автобан www.avtoban-belgee.ru либо по тел.: +7 343 344 42 42
        </p>
        <h4 class="footer__disclaimer-title">
          Выгода при покупке в Трейд-Ин<br />на Belgee X50 до 185 000 рублей
        </h4>
        <p class="footer__disclaimer-text">
          Реклама. ООО «Автобан-Березовский-Плюс». Не оферта. Количество
          автомобилей ограничено. ООО «Слава Моторс Рус» вправе изменить сроки и
          условия Программы. Подробные условия и наличие автомобилей уточняйте у
          менеджеров Автобан www.avtoban-belgee.ru либо по тел.: +7 343 344 42
          42<br /><br />Выгода 185 000 рублей достигается по программе
          «Трейд-ин». Программа Трейд-ин - возможность продажи автомобиля с
          пробегом, принадлежащего покупателю, в зачет стоимости нового
          автомобиля «Belgee X50» (далее – «Программа»). Программа действует с
          01.08.2024 г. по 31.08.2024 г. на новые автомобили «Belgee X50».
          Единовременная и разовая скидка 185 000 рублей на все комплектации,
          предоставляется Дилером покупателю от розничной цены Автомобиля,
          приобретаемого по Программе, при сдаче в Трейд-ин автомобилей «Belgee»
          или другой популярной марки (список марок уточняйте в дилерских
          центрах «B»).
        </p>
        <h4 class="footer__disclaimer-title">
          Выгода при покупке в кредит<br />на Belgee X50 до 135 000 рублей
        </h4>
        <p class="footer__disclaimer-text">
          При приобретении Конечным покупателем Автомобиля с использованием
          кредитных средств Банка по условиям программы, Дилер снижает Розничную
          цену Нового Автомобиля для Конечного покупателя.<br /><br />*Выгода по
          программе «Belgee Finance» предоставляется при покупке нового
          автомобиля «Belgee» в кредит в любом банке для «Belgee X50» (все
          комплектации) в размере 135 000 рублей.<br /><br />Реклама. ООО
          «Автобан-Березовский-Плюс». Не оферта. Программа действует с
          01.08.2024 г. по 31.08.2024 г. Количество автомобилей ограничено. ООО
          «Слава Моторс Рус» вправе изменить сроки и условия Программы.
          Подробные условия и наличие автомобилей уточняйте у менеджеров ДЦ
          Автобан www.avtoban-belgee.ru либо по тел.: +7 343 344 42 42
        </p>
        <h4 class="footer__disclaimer-title">Специальная цена</h4>
        <p class="footer__disclaimer-text">
          Максимальная цена перепродажи, с учетом применимых выгод состоит из
          выгоды по программе «Трейд-ин» и выгоды при покупке с использованием
          кредитных средств. Настоящее предложение, включая любые сведения о
          предоставляемой выгоде, носит исключительно информационный характер,
          не является офертой, подробности у официального дилера «Belgee».
          Предложение действительно с 01.08.2024 г. по 31.08.2024 г.<br /><br />Реклама.
          ООО «Автобан Березовский Плюс». Не оферта. Количество автомобилей
          ограничено. ООО «Слава Моторс Рус» вправе изменить сроки и условия
          Программы. Подробные условия и наличие автомобилей уточняйте у
          менеджеров ДЦ Автобан www.avtoban-belgee.ru либо по тел.: +7 343 344
          42 42
        </p>
        <br />
        <h3 class="footer__disclaimer-title">Belgee X70</h3>
        <h4 class="footer__disclaimer-title">
          Максимальная выгода<br />до 450 000 рублей на Belgee X70 (выгода в
          трейд-ин (лояльный/специальный) + выгода в кредит)
        </h4>
        <p class="footer__disclaimer-text">
          Выгода в размере 450 000 рублей указана для автомобилей «Belgee X70»
          (все комплектации) и состоит из выгоды по программе «Трейд-ин» в
          размере 250 000 рублей и выгоды при покупке с использованием кредитных
          средств Банка в размере 200 000.<br /><br />Программа Трейд-ин -
          возможность продажи автомобиля с пробегом, принадлежащего покупателю,
          в зачет стоимости нового автомобиля «Belgee». Единовременная скидка
          250 000 рублей на указанные выше автомобили предоставляется Дилером
          покупателю от розничной цены Автомобиля, приобретаемого по Программе,
          при сдаче в трейд-ин автомобиля марки «Belgee» или другой популярной
          марки (список марок уточняйте в дилерских центрах «Belgee»).<br /><br />При
          приобретении конечным покупателем Автомобиля с использованием
          кредитных средств Банка, по условиям Программы, Дилер снижает
          розничную цену нового Автомобиля для конечного покупателя на сумму не
          менее 200 000 рублей. Выгода по Программе предоставляется при покупке
          нового автомобиля «Belgee» в кредит в любом банке.<br /><br />Реклама.
          ООО «Автобан-Березовский-Плюс». Не оферта. Программа действует с
          01.08.2024 г. по 31.08.2024 г. Количество автомобилей ограничено. ООО
          «Слава Моторс Рус» вправе изменить сроки и условия Программы.
          Подробные условия и наличие автомобилей уточняйте у менеджеров ДЦ
          Автобан www.avtoban-belgee.ru либо по тел.: +7 343 344 42 42
        </p>
        <h4 class="footer__disclaimer-title">
          Выгода при покупке в Трейд-Ин<br />на Belgee X70 до 250 000 рублей
        </h4>
        <p class="footer__disclaimer-text">
          Реклама. ООО «Автобан-Березовский-Плюс». Не оферта. Количество
          автомобилей ограничено. ООО «Слава Моторс Рус» вправе изменить сроки и
          условия Программы. Подробные условия и наличие автомобилей уточняйте у
          менеджеров Автобан www.avtoban-belgee.ru либо по тел.: +7 343 344 42
          42<br /><br />Выгода 250 000 рублей достигается по программе
          «Трейд-ин». Программа Трейд-ин - возможность продажи автомобиля с
          пробегом, принадлежащего покупателю, в зачет стоимости нового
          автомобиля «Belgee X70» (далее – «Программа»). Программа действует с
          01.08.2024 г. по 31.08.2024 г. на новые автомобили «Belgee X70».
          Единовременная и разовая скидка 250 000 рублей на все комплектации,
          предоставляется Дилером покупателю от розничной цены Автомобиля,
          приобретаемого по Программе, при сдаче в Трейд-ин автомобилей «Belgee»
          или другой популярной марки (список марок уточняйте в дилерских
          центрах «Belgee»).
        </p>
        <h4 class="footer__disclaimer-title">
          Выгода при покупке в кредит<br />на Belgee X70 до 200 000 рублей
        </h4>
        <p class="footer__disclaimer-text">
          При приобретении Конечным покупателем Автомобиля с использованием
          кредитных средств Банка по условиям программы, Дилер снижает Розничную
          цену Нового Автомобиля для Конечного покупателя.<br /><br />*Выгода по
          программе «Belgee Finance» предоставляется при покупке нового
          автомобиля «Belgee» в кредит в любом банке для «Belgee X70» (все
          комплектации) в размере 200 000 рублей.<br /><br />Реклама. ООО
          «Автобан-Березовский-Плюс». Не оферта. Программа действует с
          01.08.2024 г. по 31.08.2024 г. Количество автомобилей ограничено. ООО
          «Слава Моторс Рус» вправе изменить сроки и условия Программы.
          Подробные условия и наличие автомобилей уточняйте у менеджеров ДЦ
          Автобан www.avtoban-belgee.ru либо по тел.: +7 343 344 42 42
        </p>
        <h4 class="footer__disclaimer-title">Специальная цена</h4>
        <p class="footer__disclaimer-text">
          Максимальная цена перепродажи, с учетом применимых выгод состоит из
          выгоды по программе «Трейд-ин» и выгоды при покупке с использованием
          кредитных средств. Настоящее предложение, включая любые сведения о
          предоставляемой выгоде, носит исключительно информационный характер,
          не является офертой, подробности у официального дилера «Belgee».
          Предложение действительно с 01.08.2024 г. по 31.08.2024 г.
          <br /><br />Реклама. ООО «Автобан Березовский Плюс». Не оферта.
          Количество автомобилей ограничено. ООО «Слава Моторс Рус» вправе
          изменить сроки и условия Программы. Подробные условия и наличие
          автомобилей уточняйте у менеджеров ДЦ Автобан www.avtoban-belgee.ru
          либо по тел.: +7 343 344 42 42
        </p>
      </div>
    </transition>
  </section>
</template>

<script>
export default {
  name: "FooterComponent",

  data() {
    return {
      expanded: false,
    };
  },

  computed: {
    getCurrentYear() {
      return new Date().getFullYear();
    },
  },

  methods: {
    togglePrivacy() {
      this.emitter.emit("open-privacy", {
        open: false,
      });
    },
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;
      getComputedStyle(element).height;
      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },

  mounted() {
    this.emitter.on("open-privacy", (data) => {
      this.privacyIsHidden = data.open;
    });
  },
};
</script>
