<template>
  <div class="swiper-main-slide-1">
    <slot name="video"></slot>
    <slot name="image"></slot>
  </div>
  <!-- <div class="swiper-main-slide__content">
    <h2 class="swiper-main-slide__title">
      <slot name="title"></slot>
    </h2>

    <slot name="counter"></slot>

    <button
      @click="toggleRequestForm($event)"
      class="swiper-main-slide__content__button btn-primary"
    >
      <slot name="sliderButton"></slot>
    </button>
  </div> -->
</template>

<script>
// import TimeCounter from "./TimeCounter.vue";

export default {
  name: "SliderComponent",
  // components: { TimeCounter },
  data() {
    return {};
  },
  // methods: {
  //   toggleRequestForm(event) {
  //     this.emitter.emit("open-request-form", {
  //       open: false,
  //       title: event.target.innerText,
  //       form_position: "форма первого баннера",
  //     });
  //   },
  // },
};
</script>
