<template>
  <section id="promo" class="main-swiper-container">
    <swiper
      class="swiper-main"
      :modules="modules"
      :slides-per-view="1"
      :pagination="{ clickable: true }"
      :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
      }"
    >
      <swiper-slide class="swiper-main-slide">
        <SliderComponent>
          <template #image>
            <div class="second-swiper__image">
              <picture>
                <source
                  srcset="@/img/pics/backgrounds/second-slider/slide-1-m.jpg"
                  media="(max-width: 767px)"
                />
                <img
                  src="@/img/pics/backgrounds/second-slider/slide-1.jpg"
                  alt=""
                />
              </picture>
            </div>
          </template>

          <template #title>
            <p>Программа Трейд-ин с дополнительной выгодой от Belgee</p>
          </template>
          <template #sliderButton> Получить предложение </template>
        </SliderComponent>
      </swiper-slide>
      <swiper-slide class="swiper-main-slide swiper-main-slide--second">
        <SliderComponent>
          <template #image>
            <div class="second-swiper__image">
              <picture>
                <source
                  srcset="@/img/pics/backgrounds/second-slider/slide-2-m.jpg"
                  media="(max-width: 767px)"
                />
                <img
                  src="@/img/pics/backgrounds/second-slider/slide-2.jpg"
                  alt=""
                />
              </picture>
            </div>
          </template>

          <template #title>
            <p>
              Оставьте заявку и получите выгодные условия по программе
              кредитования
            </p>
          </template>
          <template #sliderButton> Получить предложение </template>
        </SliderComponent>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import siteData from "@/config.json";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Scrollbar, A11y, Navigation, Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import SliderComponent from "./SliderComponent.vue";
export default {
  name: "MainSlider",
  components: {
    Swiper,
    SwiperSlide,
    SliderComponent,
  },
  data() {
    return {
      modules: [Scrollbar, A11y, Navigation, Pagination, Autoplay],
      know_more: siteData.know_more,
      sliders: siteData.main_slider,
    };
  },
  methods: {
    toggleRequestForm(event) {
      this.emitter.emit("open-request-form", {
        open: false,
        title: "",
        form_position: "формы Тест-драйв акция гибрид",
        showDate: true,
      });
    },
  },
};
</script>
