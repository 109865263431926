<template>
  <section id="main-slider-1" class="main-swiper-container">
    <swiper
      class="swiper-main"
      :modules="modules"
      :slides-per-view="1"
      :pagination="{ clickable: true }"
    >
      <swiper-slide class="swiper-main-slide swiper-main-slide--counter">
        <div class="swiper-main__backdrop"></div>
        <picture>
          <source
            srcset="@/img/pics/backgrounds/main-slider/main-slide-1-m.jpg"
            media="(max-width: 767px)"
          />
          <img
            src="@/img/pics/backgrounds/main-slider/main-slide-1.jpg"
            class="swiper-main__image swiper-main__image-1"
          />
        </picture>
        <div class="swiper-main-slide__content">
          <div class="swiper-main-slide__counter">
            <TimeCounter />
          </div>
          <button
            @click="toggleRequestForm($event)"
            class="swiper-main-slide__content__button btn-primary"
          >
            Зафиксируйте лучшие условия
          </button>
        </div>
      </swiper-slide>

      <swiper-slide class="swiper-main-slide">
        <SliderComponent>
          <template #video>
            <video autoplay muted loop>
              <source
                media="(max-width: 767px)"
                type="video/mp4"
                src="@/img/pics/backgrounds/main-slider/main-slide-2-m.mp4"
              />
              <source
                type="video/mp4"
                src="@/img/pics/backgrounds/main-slider/main-slide-2.mp4"
              />
            </video>
          </template>
        </SliderComponent>
      </swiper-slide>

      <swiper-slide v-if="isMobile" class="swiper-main-slide">
        <SliderComponent>
          <template #video>
            <video
              autoplay
              muted
              loop
              src="@/img/pics/backgrounds/main-slider/main-slide-3-m.mp4"
            ></video>
          </template>
        </SliderComponent>
      </swiper-slide>
    </swiper>
  </section>
</template>
<script>
import siteData from "@/config.json";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

import SliderComponent from "./SliderComponent.vue";
import TimeCounter from "./TimeCounter.vue";

export default {
  name: "MainSlider",

  components: {
    Swiper,
    SwiperSlide,
    SliderComponent,
    TimeCounter,
  },

  data() {
    return {
      modules: [Pagination, Autoplay],
      know_more: siteData.know_more,
      sliders: siteData.main_slider,
      isMobile: false,
    };
  },

  beforeMount() {
    const isMobile = window.outerWidth <= 767;
    if (isMobile) this.isMobile = true;
  },

  methods: {
    toggleRequestForm(event) {
      this.emitter.emit("open-request-form", {
        open: false,
        title: event.target.innerText,
        form_position: "форма первого баннера",
      });
    },
  },
};
</script>
