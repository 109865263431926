<template>
  <section id="service" class="services">
    <h2 class="services__title section-title">{{ title }}</h2>
    <div class="services__content">
      <div class="services__details">
        <h2 class="services__heading">{{ heading }}</h2>
        <ul class="services__list">
          <li
            v-for="(item, index) in list"
            :key="index"
            class="services__list-item"
          >
            {{ item }}
          </li>
        </ul>
        <button
          @click="toggleRequestForm($event)"
          class="btn-primary services__button"
        >
          {{ button_title }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import siteData from "@/config.json";

export default {
  name: "ServiceComponent",
  data() {
    return {
      title: siteData.service.title,
      heading: siteData.service.heading,
      list: siteData.service.list,
      button_title: siteData.service.button,
    };
  },
  methods: {
    toggleRequestForm(event) {
      this.emitter.emit("open-request-form", {
        open: false,
        title: event.target.innerText,
        form_position: "форма сервиса",
      });
    },
  },
};
</script>
